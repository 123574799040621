import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Posts from "../components/posts"

const BlogArchive = ({ data, location }) => {
  const posts = data.allMarkdownRemark.nodes

  return (
    <Layout location={location} title="blog" noHeading>
      <Posts data={posts} groupedByYear />
    </Layout>
  )
}

export default BlogArchive

export const Head = () => <Seo title="Archive" />

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMM DD, YYYY")
          title
        }
      }
    }
  }
`